<template>
  <div class="announcements">
    <Header collection="Newsfeed"></Header>
    <div class="announcements-body">
      <div class="head">
        <a-input v-model="keywords" allowClear class="head-input"></a-input>
        <a-button type="primary" @click="handleSearch">Search</a-button>
      </div>

      <a-spin :spinning="isLoading">
        <div class="list-head">
          <div class="title">
            Title
          </div>
          Date
        </div>
        <div class="line_"></div>
        <router-link
          v-for="item in datas"
          :key="item.n_id"
          style="color:#8b8e99"
          :to="{ name: 'announcementDetail', params: { id: item.n_id } }"
        >
          <div class="list-item">
            <div
              :style="{
                marginLeft:
                  !item.name && item.title.startsWith('【') ? '-7px' : 'unset',
              }"
            >
              <span class="list-item-name">{{ item.name }}</span>
              {{ item.title }}
            </div>
            <span>{{ getTime(item.start_time) }}</span>
          </div>
        </router-link>
      </a-spin>
      <div class="footer-pagination">
        <a-pagination
          @change="onPageChange"
          v-model="current"
          size="small"
          :total="total"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Header from "@/components/public/Header.vue";
import apis from "@/api/const";
import axios from "@/utils/request";

export default {
  components: {
    Header,
  },
  data() {
    return {
      isLoading: false,
      datas: [],
      current: 1,
      total: 1,
      page_size: 10,
      keywords: "",
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getTime(time) {
      return time?.split(" ")?.[0];
    },
    handleSearch() {
      this.current = 1;
      this.getList();
    },
    onPageChange() {
      this.getList();
    },
    getList() {
      this.isLoading = true;
      axios
        .get(apis.dashboard.noticeList, {
          params: {
            page: this.current,
            page_size: this.page_size,
            keywords: this.keywords,
          },
        })
        .then((res) => {
          this.isLoading = false;
          if (res.code === "0200") {
            this.datas = res.payload.list;
            this.total = Number(res.payload.total);
          } else {
            this.$message.error(res.message || "");
          }
        });
    },
  },
};
</script>
<style lang="less" scoped>
.announcements {
  &-body {
    width: 90.5714rem;
    height: 49.2857rem;
    background: #ffffff;
    border-radius: 16px;
    padding: 2.2857rem;
    margin-top: 1.7143rem;

    .list-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 2.2857rem;
      .title {
        font-size: 1.4286rem;
        font-family: "prefer-bold";
      }
    }
    .line_ {
      width: 100%;
      height: 1px;
      background: #f5f5f5;
      margin: 1.2857rem 0;
    }
    .list-item {
      display: flex;
      align-items: center;
      font-size: 1rem;
      justify-content: space-between;
      margin: 1.2857rem 0;
      cursor: pointer;
      span {
        color: #999999;
      }
      &-name {
        font-family: "prefer-bold";
        color: #000 !important;
      }
    }
  }
  .head {
    display: flex;
    align-items: center;
    &-input {
      width: 40rem;
      height: 2.8571rem;
      margin-right: 1.1429rem;
    }
  }
}
/deep/ .head-input .ant-input {
  height: 100%;
}
</style>
